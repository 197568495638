#default {
	h2 {
		@apply .text-xl {} }

	.body-text {
		@apply .px-05 {} } }

.pdf-download {
	@apply .text-center .my-1 {}
	a {
		@apply .bg-black .text-grey .inline-block .rounded-full .px-1 .font-headings .uppercase .whitespace-no-wrap .py-025 {}
		&:hover {
			@apply .bg-white .text-black {} } } }

.copy {
	a {
		@apply .underline {} } }
