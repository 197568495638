//base html stuff
html,
body {
	height: 100%;
	min-height: 100%;
	@apply .bg-grey .text-black .font-body {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-moz-osx-font-smoothing: grayscale;

	font-size: 120%;
	@screen sm {
		font-size: 140%; } }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

* {
	-webkit-appearance: none !important;
	-webkit-border-radius: 0px; }

img {
	display: block;
	width: 100%; }

#preload-bar {
	@apply .bg-grey .fixed .w-full .top-0 .left-0 .right-0 .z-30 {}
	height: 3px;

	svg {
		height: 3px; }

	path {
		@apply .stroke-current .text-black {} } }

#preload-counter {
	@apply .fixed .z-30 .top-0 .right-0 .mt-1 .mr-1 .pointer-events-none {} }

#preload-overlay {
	@apply .bg-grey .fixed .w-full .h-full .top-0 .left-0 .z-20 {} }

#barba-wrapper {
	@apply .relative .z-10 {}
	flex: 1; }

.barba-container {
	@apply .w-full .h-full .flex .flex-col .h-full {} }

.parsley-errors-list {
	display: none; }

.site-wrapper {
	@apply .flex .flex-col .h-full {} }
