#hello-rooftop-bar {
	@screen mobile {
		min-height: 100vh; }

	header {
		@apply .bg-transparent .border-none .z-20 {} }

	nav {
		display: none; }

	.video-container {
		@apply .fixed {}
		top: -1px;
		bottom: -1px;
		width: 100%;
		height: 100%;
		overflow: hidden;

		video {
			@apply .absolute {}
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: auto;
			height: auto;
			min-width: 100%;
			min-height: 100%;
			max-width: none; } }


	.page-content {
		width: 90%;
		position: relative;
		margin: 0 auto;
		max-width: 800px;

		.page-title {
			@screen tablet {
				max-width: 500px;
				margin: 0 auto; } }

		.subscribe-form {
			input {
				@apply .text-black {} }
			button {
				@apply .bg-white .text-black {}
				&:hover {
					@apply .bg-black .text-white {} } } } }


	.links {
		@apply .text-white .uppercase .absolute .z-20 .leading-snug .text-center {}
		bottom: 1rem;
		left: 1rem;
		right: 1rem;

		@screen md {
			@apply .flex .justify-between {}
			left: 2rem;
			right: 2rem;

			.left {
				@apply .text-left {} }

			.right {
				@apply .text-right {} } }

		a:hover {
			@apply .underline {} } } }
