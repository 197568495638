#landing {
	@apply .fixed .bg-grey .inset-0 .flex .flex-col .items-center .overflow-scroll .pointer-events-none {}
	z-index: 99999;
	opacity: 0;
	&.show {
		@apply .pointer-events-auto {}
		opacity: 1; }

	&.fade {
		transition: all 1s ease; }

	&.hide {
		transition: all 1s ease; }

	.landing-header {
		.logo {
			max-width: 280px;
			@screen sm {
				@apply .mt-3 .mb-15 {} }
			@screen xl {
				@apply .mt-15 {} }
			svg {
				fill: black;
				width: 100%;

				#A-woke,
				#A-enlightened {
					transition: opacity 0.7s ease-out; }

				#A-enlightened {
					opacity: 0; } }

			&:hover {
				svg {
					#A-woke {
						opacity: 0; }
					#A-enlightened {
						opacity: 1; } } }

			@screen mobile {
				@apply .mt-05 .mb-05 {}
				max-width: 180px; } } }

	.landing-body {
		@apply .w-full .relative {}
		flex: 1;

		.landing-mobile {
			@apply .block .text-white .text-center .uppercase .leading-none {}
			@screen lg {
				display: none; }

			ul {
				@apply .flex .flex-col {} }

			.nav-title {
				@apply .font-headings {} }

			.nav-subtitle {
				@apply .text-sm {} }

			li {
				@apply .py-05 {}

				&.hello {
					@apply .bg-red {} }

				&.hall {
					@apply .bg-purple {} }

				&.goodwill {
					@apply .bg-green {} }

				&.facebook,
				&.contact {
					@apply .bg-black {} }

				&.instagram,
				&.whats-on {
					@apply .text-black {} } } }


		.landing-desktop {
			display: none;
			@screen lg {
				display: flex; } }

		svg {
			@apply .absolute {}
			height: 75%;
			left: calc(50% - 10px);
			top: 50%;
			transform: translate3d(-50%, -55% , 1px);

			.svg-element {
				mix-blend-mode: multiply; }

			#goodwill.st0 {
				fill: #00A385;
				&:hover {
					fill: #EC3A28; } }

			#rainbow-windows.st1 {
				fill: url(#rainbow-windows-gradient); }

			#rainbow.st2 {
				fill: url(#rainbow-gradient); }

			#rainbow-group:hover {
				#rainbow-windows.st1 {
					fill: url(#rainbow-gradient); }

				#rainbow.st2 {
					fill: url(#rainbow-windows-gradient); } }

			#hello.st3 {
				fill: #EC3A28;
				&:hover {
					fill: #F78F00; } }

			#hall.st4 {
				fill: #68539C; }

			#hall-windows.st5 {
				fill: #0174BA; }

			#hall-group:hover {
				#hall.st4 {
					fill: #0174BA; }

				#hall-windows.st5 {
					fill: #68539C; } }

			.element-title {
				@apply .pointer-events-none {}
				path {
					fill: white; } } } } }
