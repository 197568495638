header {
	@apply .bg-purple .text-white .flex .items-center .flex-col .uppercase .border-b .border-black {}
	flex-shrink: 0;
	transition: background 0.3s ease;

	@screen mobile {
		@apply .pb-05 {} }

	.logo {
		@apply .cursor-pointer {}
		max-width: 280px;
		@screen sm {
			@apply .mt-05 {} }
		@screen xl {
			@apply .mt-05 {} }
		svg {
			fill: white;
			width: 100%;

			#A-woke,
			#A-enlightened {
				transition: opacity 0.7s ease-out; }

			#A-enlightened {
				opacity: 0; } }

		&:hover {
			svg {
				#A-woke {
					opacity: 0; }
				#A-enlightened {
					opacity: 1; } } }

		@screen mobile {
			@apply .mt-05 {}
			max-width: 180px; } }

	&.active {
		ul.nav {
			@apply .flex .flex-col {} } } }

ul.nav,
ul.sites {
	display: none; }

nav {
	transition: all 0.3s ease;
	@screen md {
		@apply .mt-025 .mx-1 .mb-1 {} }
	@screen mobile {
		width: 100%; } }

ul.nav {
	@screen md {
		@apply .flex .flex-row {} }

	@screen mobile {
		@apply .border-t .border-white {}
		width: 100%; }

	li {
		@screen md {
			@apply .mx-05 {}

			&:hover > a,
			&.active > a {
				@apply .border-white .border-b-2 {} } }

		@screen mobile {
			@apply .border-b .text-center {}
			width: 100%; }

		&.repent.active {
			pointer-events: none; } } }

ul.current-site {
	@apply .text-sm {}
	@screen mobile {
		@apply .hidden {} }

	@screen md {
		@apply .absolute .flex {}
		top: 1rem;
		left: 1.5rem;
		li {
			@apply .px-05 {} } } }

ul.sites {
	@apply .text-sm .text-center .py-05 {}
	@screen mobile {
		@apply .text-center .py-05 {}
		li {
			@apply .py-025 {} } }

	@screen md {
		@apply .flex {}
		li {
			@apply .px-1 .relative {}

			&:not(:last-child):after {
				content: "";
				position: absolute;
				right: 0;
				height: 50%;
				top: 25%;
				width: 1px;
				background: white; }

			&:hover > a {
				@apply .border-white .border-b-2 {} } } } }

.nav-trigger {
	display: none;
	@screen mobile {
		@apply .block .text-center {} } }


#navticker {
	@apply .bg-orange .border-b .border-black .font-headings .overflow-hidden .uppercase {}
	.ticker {
		&-content {
			@apply .flex .items-center {} }
		img {
			@apply .inline-block .mx-05 {}
			width: 1rem; }
		span {
			@apply .mx-05 {} } } }
