footer {
	@apply .relative .bg-white .w-full .bottom-0 {}
	z-index: 11;
	opacity: 1;
	transition: all 0.3s ease;
	.footer-container {
		@apply .w-full .bottom-0 .bg-white .text-sm .uppercase .py-025 .border-b .border-black {}
		flex-shrink: 0;

		@screen md {
			@apply .flex .justify-between .py-05 .px-2 {}

			.left {
				span {
					@apply .mr-1 {} } }
			.right {
				span {
					@apply .ml-1 {} } }

			span {
				white-space: normal; } }

		@screen mobile {
			@apply .text-center .px-1 {}
			span {
				@apply .block {}
				white-space: normal; } } }

	span {

		&.grouped {
			margin: 0;
			& > * {
				@apply .px-05 .inline-block {} } } }

	.hide-mobile {
		@screen mobile {
			display: none; } }

	&.hide {
		opacity: 0;
		pointer-events: none; } }


.sub-footer {
	@apply .bg-grey .px-05 {}
	color: rgba(black,0.5); }
