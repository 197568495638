.container {
	max-width: 1400px;
	&.c900 {
		max-width: 900px; }
	&.c65p {
		max-width: 65%; }
	&.c80p {
		max-width: 80%; } }

.btn {
	@apply .text-grey .inline-block .rounded-full .px-1 .font-headings .uppercase .whitespace-no-wrap .py-025 {}

	&.white {
		@apply .bg-white .text-black {} }

	&.active {
		@apply .bg-purple {} }

	&.filter:hover {
		@apply .bg-white .text-black {} } }

.row {
	@apply .leading-none .border-b .border-black {}
	&.hover-highlight:hover,
	&.highlight {
		@apply .bg-green .uppercase {}
		.has-tickets {
			@apply .bg-red {} } }

	&.hover-highlight {
		@apply .uppercase {} }

	&.last {
		flex: 1;
		.container.flex {
			height: 100%; } }

	.container > div:not(.video-container) {
		@apply .px-05 .py-05 {}
		@screen xl {
			@apply .px-15 {} } }

	.left {
		width: 100px;
		flex-grow: 0;
		flex-shrink: 1;
		@screen sm {
			width: 170px;
			@apply .border-r .border-black {} }
		@screen xl {
			@apply .border-l {}
			width: 15%; } }

	div.mid {
		flex: 1;
		width: 70%;
		@screen xl {
			width: 70%; }
		&.title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis; }
		@screen mobile {
			width: 70%; }
		@screen desktop {
			&.extra-pad {
				@apply .px-3 {} } } }

	.slideshow {
		overflow: hidden;
		padding: 0 !important; }

	.right {
		width: 100px;
		flex-grow: 0;
		flex-shrink: 1;
		@screen sm {
			@apply .block .border-l .border-r .border-black {}
			width: 170px; }
		@screen xl {
			width: 15%; } }

	&.alt-tickets-btn,
	&.alt-back-btn,
	&.alt-news-info {
		@screen xl {
			display: none; }
		a {
			display: inline-block;
			width: 100%; } }

	&.alt-news-info {
		font-size: 0.9rem;
		.container {
			justify-content: space-between;
			.backlink {
				@apply .border-l .border-black {}
				padding: 0px;
				a {
					@apply .px-05 .py-05 {}
					width: 100%;
					height: 100%;
					text-align: right;
					&:hover {
						@apply .bg-black .text-grey {} } } } } }

	.feature-image {
		@apply .absolute .hidden {}
		z-index: 9999;
		width: 250px;
		&.show {
			@apply .block {} } }

	.article-feature-image {
		@apply .mb-1 {}
		@screen md {
			@apply .my-1 {} } }

	.small {
		@apply .block .uppercase .text-xs {} } }


.row-group {
	.row {
		&:not(:last-child) {
			border-bottom: none;
			.mid,
			.right {
				@apply .border-b .border-black {} } } } }


.article-wrapper {
	@apply .relative {} }

#articles {
	@apply .flex-wrap .border-l .border-black {}
	.article {
		@apply .flex .flex-col .border-r .border-black .relative .z-10 {}
		img {
			@apply .border-b .border-black {} }

		flex-basis: 50%;
		@screen md {
			flex-basis: 33%; }
		@screen lg {
			flex-basis: 25%;
 }			// &:nth-child(4n+1)

		&:hover .article-info {
			@apply .bg-green {} }

		// &:first-child .article-info
		// 	&:before
		// 		@apply .bg-black
		// 		content: ""
		// 		height: 1px
		// 		width: 1000%
		// 		left: -500%
		// 		position: absolute
		// 		top: 0
		// 		z-index: 0
		// 	&:after
		// 		@apply .bg-black
		// 		content: ""
		// 		height: 1px
		// 		width: 1000%
		// 		left: -500%
		// 		position: absolute
		// 		bottom: 0
 }		// 		z-index: 0

	.article-info {
		@apply .border-b .border-black .leading-none .relative .bg-grey {}
		flex: 1;

		h2 {
			@apply .mb-0 .mt-025 {}
			line-height: 1.1; } } }

.h-line {
	@apply .bg-black {}
	height: 1px;
	position: absolute;
	left: 0;
	right: 0; }

.email_link {
	&:hover {
		text-decoration: underline; } }

#ot_bucket {
	display: none; }

.two-col {
	@screen md {
		@apply .flex .justify-between {}
		& > div {
			width: 49%; } } }

#map {
	@screen mobile {
		min-height: 350px; } }


.subscribe-form {
	@apply .my-1 .mx-auto .text-base {}
	max-width: 700px;

	.form-row {
		@apply .flex .flex-col {}
		@screen md {
			@apply .flex-row {} }

		&:first-of-type {
			.form-field {
				border-bottom: none; } } }

	input {
		@apply .border .border-black .bg-offwhite .px-05 .py-025 {}
		flex: 1;

		@screen md {
			&:first-child {
				border-right: 0; } }

		&::placeholder {
			@apply .text-black .uppercase {} }

		&.parsley-error {
			@apply .text-red {}
			&::placeholder {
				@apply .text-red {} } } }

	button {
		@apply .font-headings .uppercase .bg-pink .border .border-black .px-1 .py-025 .border-t-0 {}
		@screen md {
			@apply .border-t {} }

		&:hover {
			@apply .bg-black .text-white {} } }

	ul.parsley-errors-list {
		@apply .text-red {} } }

.gradient {
	background: linear-gradient(90deg, rgba(255,238,0,1) 0%, rgba(106,78,154,1) 7.142857143%, rgba(241,150,170,1) 14.28571429%, rgba(241,142,0,1) 21.42857143%, rgba(0,165,133,1) 28.57142857%, rgba(0,113,184,1) 35.71428572%, rgba(230,44,48,1) 42.85714286%, rgba(255,238,0,1) 50%, rgba(255,238,0,1) 50%, rgba(106,78,154,1) 57.14285714%, rgba(241,150,170,1) 64.28571429%, rgba(241,142,0,1) 71.42857143%, rgba(0,165,133,1) 78.57142857%, rgba(0,113,184,1) 85.71428572%, rgba(230,44,48,1) 92.85714286%, rgba(255,238,0,1) 100%);

	animation-name: gradient;
	animation-duration: 8s;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
	background-size: 400%;
	background-position: 100% 0%;

	@keyframes gradient {
		from {
			background-position: 200% 0%; }
		to {
			background-position: 0% 0%; } } }


.link:hover {
	@apply .underline {} }

.event-details-dates {
	padding: 0 !important;

	.edd-list {
		.date {
			@apply .border-b .border-black {}
			padding: 0.25rem 3rem;
			display: flex;
			p {
				@apply .text-base .leading-snug {}
				flex: 1 1 50%;
				&:last-child {
					text-align: right; } }
			&:last-child {
				border: none; } } } }

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; } }

.image-container {
	@apply .my-1 {} }

.mins[data-minutes^="00"] {
		display: none; }

.tippy-tooltip.none-theme {
	background-color: transparent !important; }

/* PopUp Styling: */
#announcement-popup {
	display: none;
	position: fixed;
	overflow-y: scroll;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(255,0,0,0.4);
	animation: fade-in .25s ease-in;
	animation-name: fade-in .25s ease-in;

	&.open {
		display: block; }

	.popup-content {
		@apply .bg-grey {}
		@screen mobile {
			max-width: 90%; }

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

		width: auto;
		// margin: 0 auto
		padding: 1rem;
		text-align: center;

		.popup-img {
			@apply .bg-white {}
			text-align: center;
			img {
				max-height: 50vh;
				display: inline-block;
				width: auto; } }

		.content {
			@apply .bg-white {}
			padding: 1rem 0 0;
			font-size: 0.9rem;
			.close-go {
				cursor: pointer; }
			.close {
				display: block;
				padding: 0.5rem 0;
				cursor: pointer; } } } }


#ackpopup {
	@apply fixed inset-0 z-50 bg-black bg-opacity-75 pointer-events-none transition-all opacity-0 flex justify-center items-center duration-200 {}
	backdrop-filter: blur(5px);
	&.active {
		@apply pointer-events-auto opacity-100 {} }

	.popup-content {
		@apply relative text-base p-2 bg-black text-white w-full {}
		width: 760px;
		max-width: 90%; }

	.popup-close-btn {
		@apply text-white absolute cursor-pointer {}
		top: -2rem;
		right: 0; }

	.popup-btn {
		@apply underline mt-1 uppercase cursor-pointer {} } }
