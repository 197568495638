#goodwill-club {
	@apply .bg-black .text-white {}

	header {
		@apply .bg-black .mb-2 {} }

	nav {
		display: none; }

	.header {
		@apply .mt-4 .relative {}

		.animation {
			@apply .absolute {}
			left: 50%;
			top: 0;
			transform: translate(-50%, 0);
			max-width: 6%; }

		.graphic {
			@apply .pt-4 .my-4 {}

			&-arrows {
				@apply .pt-4 .pointer-events-none {}
				position: fixed;
				left: 50%;
				transform: translateX(-50%);
				right: 0;
				max-width: 1400px;
				width: 100%;

				svg {
					width: 100%; } } } }

	.row {
		.left,
		.right,
		.mid {
			@apply .relative {}
			width: 33.3333%; } }

	.bug {
		@apply .relative {}
		left: 50%;
		transform: translateX(-50%);
		max-width: 50%; }

	.frog {
		@apply .absolute {}
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		max-width: 50%; }

	.good {
		@apply .py-4 {}
		max-width: 50%; }

	.body-text {
		@apply .text-center .px-1 {} }

	.social-links {
		@apply .leading-snug {}
		margin-top: 1rem;
		@screen desktop {
			margin-top: -6rem; } }

	#goodwillSlideshow {
		@apply .my-2 {}
		@screen desktop {
			@apply .my-4 {} } }

	.slideshow-nav {
		@apply .absolute .inset-0 {}

		.prev,
		.next {
			@apply .absolute .cursor-pointer {}
			top: 50%;
			transform: translateY(-50%);
			max-width: 15%; }

		.prev {
			left: 5%; }

		.next {
			right: 5%; } }


	.subscribe-form {
		input {
			@apply .bg-black .border-white .text-white {}
			&::placeholder {
				@apply .text-white {} } }
		button {
			@apply .bg-white .text-black .border-white {} } }

	.btn:hover {
		@apply .bg-black .text-white {} } }
