.festival-landing-button {
	@apply text-white w-full font-headings uppercase text-center {} }

.barba-container#festival {
	.hero {
		@apply relative {}
		.hero-button {
			@apply absolute inset-0 flex justify-center items-center {} } }

	.copy,
	.section-heading {
		@screen mobile {
			@apply px-1 {} } }

	.events-feed {
		@apply my-1 grid grid-cols-2 gap-1 mx-1 {}
		@screen md {
			@apply my-2 grid-cols-3 {} } } }
