//typography
h1 {
	@apply .font-headings .uppercase {}
	@screen md {
		@apply .text-lg {} } }

h2 {
	@apply .mb-1 .leading-snug {} }


.body-text {
	@apply .text-base .leading-snug {}

	&:not(.no-pad) {
		p {
			@apply .mb-1 {} } } }

.section-heading {
	@apply .uppercase .border-black .border-t .border-b .text-lg .my-025 {} }
