#double-rainbow {
	@apply .bg-red .text-center {}
	header {
		@apply .bg-transparent .border-none .z-30 {} }

	nav {
		display: none; }

	.nav-trigger {
		transition: opacity 0.3s ease;
		opacity: 1;
		&.hidden {
			opacity: 0; } }

	.granim-container {
		@apply .fixed .inset-0 .w-full .h-full .z-20 .overflow-hidden .cursor-wait {}

		.granim {
			@apply .absolute .inset-0 .w-full .h-full {}
			background: repeating-linear-gradient(-45deg, rgba(247, 143, 0, 1) 0%, #ec3928 5%, #f599a9 10%, #f78f00 15%, #00a385 20%, #0074ba 25%, #ec3928 30%, #ffeb00 35%, #0074ba 40%, #f599a9 45%, rgba(247, 143, 0, 1) 50%);
			background-size: 200vw 200vw;
			-webkit-animation: 'slide' 10s infinite linear forwards;
			transform:  translateZ(0); } }

	.site-body {
		a:hover {
			@apply .underline {} } }

	img.rainbow-eyes {
		@apply .fixed .w-full .z-40 .p-2 .pointer-events-none {}
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		@screen lg {
			@apply .absolute {}
			height: 85vh; } }

	img.banner {
		@apply .px-1 .py-1 {}
		@screen md {
			@apply .py-4 {} } }

	.eye-nav {
		@apply .text-center .text-white .py-2 .relative .uppercase .z-10 {}
		background-image: url(../img/double-rainbow/eye-expansion.svg);
		background-repeat: repeat-y;
		background-position: center;
		background-size: 50% auto;
		margin: 4rem 0 5rem;
		@screen md {
			@apply .py-4 {}
			margin: 6rem 0 10rem; }

		.eye-top,
		.eye-bottom {
			@apply .absolute .z-10 {}
			width: 80%;
			left: 50%; }

		.eye-top {
			top: 0;
			transform: translate(-50%, -50%); }

		.eye-bottom {
			bottom: 0;
			transform: translate(-50%, 50%); }

		.eye-content {
			@apply .relative .z-20 {} }

		.title {
			@apply .text-xl .font-bold .font-antiqueolive .cursor-pointer .leading-tight {}
			@screen md {
				@apply .text-xxl {} }

			&:hover {
				@apply .underline {} }

			a {
				@apply .inline-block {} } }

		.accordion-wrapper {
			.accordion-content {
				@apply .font-interstate .leading-tight .text-xl {}
				display: none;

				a {
					&:hover {
						@apply .underline {} } } } } }

	h1, h2 {
		@apply .font-antiqueolive .leading-tight .uppercase .mb-0 .text-xl {}
		@screen sm {
			@apply .text-xxl {} }
		@screen md {
			@apply .text-xxxl {} } }


	h1 {
		@apply .underline .text-white {} }

	.copy {
		@apply .text-white .font-interstate .my-1 .leading-tight {}
		@screen md {
			@apply .text-xl .my-2 {} }

		p {
			@apply .mb-1 {} } }

	.pdf-download {
		a {
			@apply .font-antiqueolive .leading-tight .uppercase .mb-0 .text-xl .text-white {}
			@screen sm {
				@apply .text-xxl {} }
			@screen md {
				@apply .text-xxxl {} }

			&:hover {
				@apply text-black no-underline {} } } }

	.newsletter-signup {
		@apply .px-1 {}

		input {
			@apply .bg-red .border-black .text-black .font-body {}
			&::placeholder {
				@apply .text-black {} } }
		button {
			@apply .bg-black .text-white .border-black {} } } }


@keyframes slide {
	0% {
		background-position-x: 0%; }

	100% {
		background-position-x: 200vw; } }
